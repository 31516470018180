import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import LetterAvatar from "./LetterAvatar";
import { de } from "date-fns/locale";
import { formatDistance } from "date-fns";

interface CommentProps {
  readonly id: string;
  readonly author: string;
  readonly updatedAt: string;
  readonly text: string;
}

export const CommentComponent: React.FC<CommentProps> = ({
  id,
  author,
  text,
  updatedAt,
}) => {
  return (
    <Box key={id} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item display="flex" alignItems="center">
            <LetterAvatar name={author} mr={1} />
            <Typography sx={{ fontWeight: 600 }}>
              {author}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="text.secondary" variant="body2">
              {formatDistance(
                new Date(updatedAt),
                new Date(),
                { addSuffix: true, locale: de }
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} sx={{ pl: 1}}>
            <Typography>{text}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
