import * as React from "react";
import { IApplicant } from "../../infrastructure/api/applicants";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import SchoolIcon from "@mui/icons-material/School";
import CakeIcon from "@mui/icons-material/Cake";
import PaidIcon from "@mui/icons-material/Paid";
import EventIcon from "@mui/icons-material/Event";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StartIcon from "@mui/icons-material/Start";

interface IApplicantForm {
  handleSubmit(value: IApplicant): void;

  applicant: IApplicant;
}

// TODO: default string instead of null
const ApplicantSchema = Yup.object().shape({
  address: Yup.string().nullable(),
  number: Yup.string().nullable(),
  email: Yup.string().email().nullable(),
  experience: Yup.string().nullable(),
  education: Yup.string().nullable(),
  age: Yup.number().nullable(),
  salary: Yup.number().nullable(),
  meetingAt: Yup.date().nullable(),
  lastContact: Yup.date().nullable(),
});

export default function ApplicantForm(props: IApplicantForm) {
  const formik = useFormik({
    initialValues: props.applicant,
    validationSchema: ApplicantSchema,
    onSubmit: (values) => {
      props.handleSubmit(values);
    },
    enableReinitialize: true,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <PlaceIcon />
              <Typography>Wohnort</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="address"
              name="address"
              label=""
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <PhoneIcon />
              <Typography>Telefon</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="number"
              name="number"
              label=""
              value={formik.values.number}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
            />
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <AlternateEmailIcon />
              <Typography>E-Mail</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label=""
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <WorkHistoryIcon />
              <Typography>Berufserfahrung</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="experience"
              name="experience"
              label=""
              value={formik.values.experience}
              onChange={formik.handleChange}
              error={
                formik.touched.experience && Boolean(formik.errors.experience)
              }
              helperText={formik.touched.experience && formik.errors.experience}
            />
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <SchoolIcon />
              <Typography>Ausbildung</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="education"
              name="education"
              label=""
              value={formik.values.education}
              onChange={formik.handleChange}
              error={
                formik.touched.education && Boolean(formik.errors.education)
              }
              helperText={formik.touched.education && formik.errors.education}
            />
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <CakeIcon />
              <Typography>Alter</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              type={"number"}
              id="age"
              name="age"
              label=""
              value={formik.values.age}
              onChange={formik.handleChange}
              error={formik.touched.age && Boolean(formik.errors.age)}
              helperText={formik.touched.age && formik.errors.age}
            />
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <PaidIcon />
              <Typography>Gehaltsvorstellung</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="salary"
              type={"number"}
              name="salary"
              inputProps={{ step: 0.01 }}
              label=""
              value={formik.values.salary}
              onChange={formik.handleChange}
              error={formik.touched.salary && Boolean(formik.errors.salary)}
              helperText={formik.touched.salary && formik.errors.salary}
            />
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <EventIcon />
              <Typography>Termin</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label=""
                value={formik.values.meetingAt}
                ampm={false}
                inputFormat={"dd/MM/yyyy HH:mm"}
                onChange={(value, second) => {
                  if (value) {
                    formik.setFieldValue("meetingAt", value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      formik.touched.meetingAt && formik.errors.meetingAt
                    }
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <ScheduleIcon />
              <Typography>Letzer Kontakt</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label=""
                value={formik.values.lastContact}
                ampm={false}
                inputFormat={"dd/MM/yyyy HH:mm"}
                onChange={(value, second) => {
                  if (value) {
                    formik.setFieldValue("lastContact", value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      formik.touched.lastContact && formik.errors.lastContact
                    }
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} alignSelf={"center"}>
            <Stack direction="row" alignItems="center" gap={1}>
              <StartIcon />
              <Typography>Frühester Beginn</Typography>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                value={formik.values.startAt}
                onChange={(value, second) => {
                  if (value) {
                    formik.setFieldValue("startAt", value);
                  }
                }}
                inputFormat={"dd/MM/yyyy"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={formik.touched.startAt && formik.errors.startAt}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} textAlign={"right"}>
            <Button
              variant={"contained"}
              type={"submit"}
              color={"secondary"}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#FF154D",
                },
              }}
            >
              Speichern
            </Button>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Divider />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
