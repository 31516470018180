import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { TableRow, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ApplicantQualification from "./ApplicantQualification";
import { IApplicant } from "../../infrastructure/api/applicants";
import { ApplicantHeader } from "./ApplicantHeader";
import { CommentComponent } from "../general/Comment";

interface IApplicantPDF {
  readonly applicant?: IApplicant;
}

export class ApplicantPDF extends React.PureComponent<IApplicantPDF> {
  private createData(name: string, value: any) {
    return { name, value };
  }

  private createRows() {
    const { applicant } = this.props;
    
    if (!applicant) {
      return [];
    }

    return [
      this.createData("Nummer", applicant.number),
      this.createData("E-Mail", applicant.email),
      this.createData("Alter", applicant.age),
      this.createData("Wohnort", applicant.address),
      this.createData("Berufserfahrung", applicant.experience),
      this.createData("Ausbildung", applicant.education),
      this.createData("Gehaltsvorstellung", applicant.salary),
      this.createData(
        "Frühester Beginn am",
        applicant.startAt
          ? new Date(applicant.startAt).toLocaleString("de-De", {
              month: "short",
              day: "numeric",
            })
          : "Kein Angabe"
      ),
    ];
  }

  render() {
    const { applicant } = this.props;
    
    if (!applicant) {
      return (
        <Box sx={{ flexGrow: 1 }} p={4}>
          <Typography>Keine Bewerber-Daten verfügbar</Typography>
        </Box>
      );
    }

    const hasComments = applicant.comments?.data && Array.isArray(applicant.comments.data);
    const campaignName = applicant.campaign?.data?.attributes?.name || "Keine Kampagne";

    return (
      <Box sx={{ flexGrow: 1 }} p={4}>
        <ApplicantHeader
          name={applicant.name || "Unbekannt"}
          campaign={campaignName}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 650, maxWidth: 1000 }}
            aria-label="simple table"
          >
            <TableBody>
              {this.createRows().map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12} p={2}>
          <ApplicantQualification
            markdown={applicant.qualification || ""}
          />
        </Grid>
        <Grid item xs={12} p={2}>
          <Typography>Kommentare:</Typography>
          {hasComments ? (
            applicant.comments.data
              .slice(0)
              .reverse()
              .map((item) => {
                const author = item?.attributes?.author?.data?.attributes?.name || "Unbekannt";
                return (
                  <CommentComponent
                    key={item.id}
                    id={item.id}
                    author={author}
                    updatedAt={item.attributes.updatedAt}
                    text={item.attributes.text || ""}
                  />
                );
              })
          ) : (
            <Typography variant="body2">Keine Kommentare vorhanden</Typography>
          )}
        </Grid>
      </Box>
    );
  }
}
