import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getApplicant,
  IApplicant,
  IComment,
} from "../../infrastructure/api/applicants";
import ApplicantCommentsSection from "./ApplicantCommentsSection";
import ApplicantForm from "./ApplicantForm";
import ApplicantQualification from "./ApplicantQualification";
import { ApplicantPDF } from "./ApplicantPDF";
import { useReactToPrint } from "react-to-print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// TODO: move to separate file
const initValues: IApplicant = {
  id: "",
  address: "",
  age: 0,
  createdAt: "",
  education: "",
  email: "mail@mail.de",
  experience: "",
  lastContact: "",
  meetingAt: "",
  name: "",
  number: "",
  salary: 0,
  startAt: "",
  status: "open",
  updatedAt: "",
  qualification: "",
  comments: {
    data: [],
  },
  attachments: {
    data: [],
  },
  campaign: {
    data: {
      id: "23",
      attributes: {
        name: "",
      },
    },
  },
};

// Define status options
const statusOptions = [
  { value: "open", label: "Offen" },
  { value: "checking", label: "In Prüfung" },
  { value: "contact", label: "Kontakt" },
  { value: "negotiation", label: "Verhandlung" },
  { value: "hired", label: "Eingestellt" },
  { value: "preparing", label: "Absage vorbereiten" },
  { value: "rejected", label: "Abgelehnt" }
];

interface IBasicModal {
  open: boolean;
  applicantId: string;

  handleClose(): void;

  handleSubmit(value: IApplicant): void;

  handleCommentSubmit(
    applicantId: string,
    comment: string
  ): Promise<IComment | undefined>;

  handleFileUploadSubmit(
    applicantId: string,
    file: File
  ): Promise<undefined>;

  handleDeleteApplicant(applicantId: string): Promise<void>;
}

export default function BasicModal(props: IBasicModal) {
  const [applicant, setApplicant] = useState(initValues);
  const [editMode, setEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchApplicant = async () => {
      if (props.applicantId !== "") {
        const response = await getApplicant(props.applicantId);
        setApplicant(response);
      }
    };
    fetchApplicant();
  }, [props.applicantId]);

  const handleComment = async (comment: string) => {
    const response = await props.handleCommentSubmit(
      props.applicantId,
      comment
    );
    if (response) {
      setApplicant({
        ...applicant,
        comments: {
          data: [...applicant.comments.data, response],
        },
      });
    }
  };

  const handleFileUpload = async (file: File) => {
    setIsUploading(true);
    try {
      const response = await props.handleFileUploadSubmit(
        props.applicantId,
        file
      );
      if (response) {
        const attachmentData = {
          // @ts-ignore:next-line
          id: response.files[0].id,
          // @ts-ignore:next-line
          attributes: response.files[0]
        }
        if (applicant.attachments.data) {
          setApplicant({
            ...applicant,
            attachments: {
              data: [...applicant.attachments.data, attachmentData],
            },
          });
        } else {
          setApplicant({
            ...applicant,
            attachments: {
              data: [attachmentData],
            },
          });
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // You could add error handling UI here if needed
    } finally {
      setIsUploading(false);
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const saveChanges = () => {
    props.handleSubmit(applicant);
    setEditMode(false);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const value = event.target.value as IApplicant['status'];
    setApplicant({
      ...applicant,
      status: value
    });
    props.handleSubmit({
      ...applicant,
      status: value
    })
  };

  const handleDeleteClick = () => {
    if (props.handleDeleteApplicant) {
      props.handleDeleteApplicant(props.applicantId);
      props.handleClose();
    }
  };

  const componentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: applicant.name.replace(" ", "_"),
    removeAfterPrint: true,
  });

  const renderSalary = (value: number | undefined) => {
    let salary = "-"
    if (value) {
      if (value > 10000) {
        salary = `${value.toLocaleString()} € pro Jahr`;
      } else if (value > 100) {
        salary = `${value.toLocaleString()} € pro Monat`;
      } else {
        salary = `${value.toLocaleString()} € pro Stunde`;
      }
    }
    return(
      <>
        <Grid item xs={5}>
          <Typography variant="body2" color="textSecondary">Gehalt</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{salary}</Typography>
        </Grid>
      </>
    )
  }

  // Function to render read-only field
  const renderField = (label: string, value: string | number) => (
    <>
      <Grid item xs={5}>
        <Typography variant="body2" color="textSecondary">{label}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography>{value || '-'}</Typography>
      </Grid>
    </>
  );

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={props.open}
      onClose={() => {
        setApplicant(initValues);
        setEditMode(false);
        props.handleClose();
      }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">{applicant.name}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {applicant.campaign?.data.attributes.name || ""}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {/* Export button */}
              <Button
              color={"secondary"}
              onClick={handlePrint}
              startIcon={<PictureAsPdfIcon />}
              >
                PDF Export
              </Button>
              <div style={{ display: "none" }}>
              <ApplicantPDF ref={componentRef} applicant={applicant} />
              </div>
              
              {/* Delete button */}
              <Button
                variant="outlined"
                color="error"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
              >
                Löschen
              </Button>

              {/* Status dropdown */}
              <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                <InputLabel id="applicant-status-label">Status</InputLabel>
                <Select
                  labelId="applicant-status-label"
                  id="applicant-status"
                  value={applicant.status}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {/* Left column - Basic information */}
          <Grid item xs={12} md={8}>
            {editMode ? (
              <ApplicantForm
                handleSubmit={(value) => {
                  props.handleSubmit(value)
                  setEditMode(false)
                }}
                applicant={applicant}
              />
            ) : (
              <Paper sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Bewerberinformationen
                    </Typography>
                  </Grid>
                  <Grid item>
                    {editMode ? (
                      <Button 
                        variant="contained" 
                        color="primary"
                        onClick={saveChanges}
                      >
                        Speichern
                      </Button>
                    ) : (
                      <Button 
                        variant="outlined" 
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={toggleEditMode}
                      >
                        Bearbeiten
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {renderField("Name", applicant.name)}
                  {renderField("E-Mail", applicant.email)}
                  {renderField("Telefon", applicant.number)}
                  {renderField("Adresse", applicant.address)}
                  {renderField("Alter", applicant.age)}
                  {renderField("Ausbildung", applicant.education)}
                  {renderField("Erfahrung", applicant.experience)}
                  {renderSalary(applicant.salary)}
                  {renderField("Frühster Start", applicant.startAt ? new Date(applicant.startAt).toLocaleDateString() : '-')}
                  {renderField("Termin", applicant.meetingAt ? new Date(applicant.meetingAt).toLocaleDateString() : '-')}
                </Grid>
              </Paper>
            )}

            {/* Qualification section */}
            <Paper sx={{ p: 3, mt: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Vorqualifizierung
              </Typography>
              <ApplicantQualification markdown={applicant.qualification} />
            </Paper>
          </Grid>

          {/* Right column - Contact information and details */}
          <Grid item xs={12} md={4}>

            {/* Document section with upload button */}
            <Paper sx={{ p: 3, mt: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Dokumente</Typography>
                <Button 
                  size="small" 
                  variant="outlined"
                  startIcon={isUploading ? <CircularProgress size={16} /> : <UploadIcon />}
                  onClick={() => {
                    // Open file dialog to upload new document
                    const fileInput = document.createElement('input');
                    fileInput.type = 'file';
                    fileInput.onchange = (e) => {
                      const file = (e.target as HTMLInputElement).files?.[0];
                      if (file) {
                        handleFileUpload(file);
                      }
                    };
                    fileInput.click();
                  }}
                  disabled={isUploading}
                >
                  {isUploading ? 'Lädt...' : 'Hochladen'}
                </Button>
              </Box>
              <Divider sx={{ mb: 2 }} />

              {/* Show loading indicator for the whole section when uploading */}
              {isUploading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              )}

              {/* List of documents */}
              {!isUploading && (
                <>
                  {applicant.attachments?.data && applicant.attachments.data.length > 0 ? (
                    <Box sx={{ maxHeight: '250px', overflow: 'auto' }}>
                      {applicant.attachments.data.map((file, index) => (
                        <Box 
                          key={file.id} 
                          sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            p: 1,
                            borderBottom: index < applicant.attachments.data.length - 1 ? '1px solid #eee' : 'none'
                          }}
                        >
                          <Typography variant="body2">
                            {file.attributes.name || `Dokument ${index + 1}`}
                          </Typography>
                          <Button 
                            href={file.attributes.url} 
                            size="small"
                            target="_blank" // Open in new tab
                            rel="noopener noreferrer" // Security best practice
                          >
                            Ansehen
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="body2" color="textSecondary" align="center" sx={{ py: 2 }}>
                      Keine Dokumente vorhanden
                    </Typography>
                  )}
                </>
              )}
            </Paper>

            {/* Comments section */}
            <Paper sx={{ p: 3, mt: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Kommentare</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              
              <ApplicantCommentsSection
                handleCommentSubmit={(comment) => handleComment(comment)}
                comments={applicant.comments?.data || []}
              />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
